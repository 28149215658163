import React, { FC, useRef } from 'react';
import { animated, config, useSpring } from 'react-spring';
import classNames from 'classnames';

import Image from 'common/Image';
import Typography from 'common/Typography';
import Badge from 'components/Badge';
import Button from 'components/Button';
import Link from 'components/Link';

import { CardFeatures, CardProps } from './models.d';

import './Card.scss';

const Feature = ({ image, text }: CardFeatures) => (
  <li className="feature" data-testid="feature-item">
    <Image {...image.imageStructure} className="feature__image" />
    <Typography
      size={16}
      color="grey"
      className="feature__description"
      dangerouslySetInnerHTML={text}
    />
  </li>
);

const Card: FC<CardProps> = ({
  image,
  title,
  description,
  label,
  badges,
  showBadge = false,
  features,
  buttons,
  preparingTime,
  amount,
  nutritionalValues,
  url,
  variant = 'article',
  color = 'color',
  className,
}) => {
  const cardItem = useRef<HTMLDivElement>(null!);

  const calc = (e) => [
    -(
      e.clientY -
      cardItem.current?.getBoundingClientRect().top -
      cardItem.current?.offsetHeight / 2
    ) / 80,
    (e.clientX -
      cardItem.current?.getBoundingClientRect().left -
      cardItem.current?.offsetWidth / 2) /
      50,
    1.02,
  ];

  const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  const [props, setSpring] = useSpring(() => ({ xys: [0, 0, 1], config: config.default }));

  const cardClasses = classNames(
    'card',
    { [`card--${variant?.toLowerCase()}`]: variant, [`card--${color}`]: color },
    className
  );

  return (
    <animated.div
      className={cardClasses}
      data-testid="card-item"
      onMouseMove={(e) => setSpring.start({ xys: calc(e) })}
      onMouseLeave={() => setSpring.start({ xys: [0, 0, 1] })}
      style={{
        transform: props.xys.to(trans),
      }}
      ref={cardItem}
    >
      {label ? (
        <Typography size={14} className="card__label" color="white">
          {label}
        </Typography>
      ) : null}
      {showBadge ? badges?.map((badge) => <Badge key={badge.content} {...badge} />) : null}

      <Link {...{ url }} className="card__link" as="div" title="content">
        <Image
          {...image.imageStructure}
          objectFit={variant === 'brand' || variant === 'product' ? 'contain' : 'cover'}
          className="card__image"
        />
      </Link>
      <div className="card__content">
        {title.toLowerCase() === 'senokot' ||
        title.toLowerCase() === 'senocalm' ||
        title.toLowerCase() === 'fybogel' ||
        title === '' ? null : (
          <Link {...{ url }} className="card__link">
            <Typography
              as="h2"
              size={{ base: 18, xs: 22 }}
              padding={{ bottom: 'md', left: 'lg', right: 'lg' }}
              className="card__title"
            >
              {title}
            </Typography>
          </Link>
        )}
        {description ? (
          <Typography
            dangerouslySetInnerHTML={description}
            size={18}
            padding={{ bottom: 'md', left: 'lg', right: 'lg' }}
            className="card__description"
          />
        ) : null}
        {features?.length ? (
          <ul className="card__features">
            {features.map((feature) => (
              <Feature {...feature} key={feature.text} />
            ))}
          </ul>
        ) : null}
        {nutritionalValues || preparingTime ? (
          <div className="card__footer">
            <Typography size={18} className="card__time">
              {preparingTime}
            </Typography>
            <Typography size={18} className="card__volume">
              {`${amount} ${nutritionalValues}`}
            </Typography>
          </div>
        ) : null}
        {buttons?.length ? (
          <div className="card__buttons">
            {buttons.filter(Boolean).map((button) => (
              <Button {...button} key={button.ariaLabel} className="card__button">
                {button?.label}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </animated.div>
  );
};

export default Card;
